<template>
  <div>
    <c-search-box @enter="getSearchList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <!-- 공정 목록 -->
        <c-tree-table
          ref="processTable"
          title="LBL0001702"
          parentProperty="upProcessCd"
          customID="processCd"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getSearchList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <!-- 공정상세 -->
          <c-card :title="title" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="신규 (상위공정)" icon="add" @btnClicked="addParent" />
                <c-btn v-if="editable" label="신규 (단위공정)" icon="add" @btnClicked="addChild" />
                <!-- 저장 -->
                <c-btn
                  v-show="editable && processEditable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="process"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <!-- 공정명 -->
                <c-text
                  :editable="editable&&processEditable"
                  :required="true"
                  label="LBL0003230"
                  name="processName"
                  v-model="process.processName">
                </c-text>
              </div>
              <div v-if="isUpProcess" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 관리부서 -->
                <c-dept 
                  :required="true"
                  :editable="editable&&processEditable"
                  :plantCd="process.plantCd" 
                  label="LBL0001721" 
                  name="managementDepts" 
                  v-model="process.managementDepts" 
                />
              </div>
              <div v-if="!isUpProcess" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-process
                  :required="true"
                  :editable="editable&&processEditable"
                  type="upprocess"
                  label="상위공정"
                  name="upProcessCd"
                  v-model="process.upProcessCd"
                  @datachange="processChange">
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 공정관리번호 -->
                <c-text
                  :required="true"
                  :editable="editable&&processEditable"
                  label="공정관리번호"
                  name="processNo"
                  v-model="process.processNo">
                </c-text>
              </div>
              <div v-if="isUpProcess" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 사업장 -->
                <c-plant
                  :required="true"
                  :editable="editable&&processEditable"
                  label="LBLPLANT"
                  name="plantCd"
                  v-model="process.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 정렬순서 -->
                <c-text
                  :editable="editable&&processEditable"
                  label="LBLSORTORDER"
                  name="orderNo"
                  v-model="process.orderNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 사용여부 -->
                <c-checkbox
                  :editable="editable&&processEditable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="process.useFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 공정설명 -->
                <c-textarea
                  :editable="editable&&processEditable"
                  label="LBL0003238"
                  name="processDesc"
                  :rows="2"
                  v-model="process.processDesc">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 공정 사진 -->
                <c-upload
                  :attachInfo="attachInfoL1Photo"
                  :editable="editable&&processEditable"
                  label="LBL0003240">
                </c-upload>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 그 밖의 유해위험정보 -->
                <c-upload
                  :attachInfo="attachInfoL1Hazard"
                  :editable="editable&&processEditable"
                  label="LBL0003241">
                </c-upload>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공정명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            label: '관리부서',
            align: 'center',
            style: 'width: 130px',
            sortable: false,
          },
          // {
          //   name: 'orderNo',
          //   field: 'orderNo',
          //   // 순번
          //   label: 'LBLSORTORDER',
          //   align: 'center',
          //   style: 'width: 70px',
          //   sortable: false,
          // },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            style: 'width: 70px',
            sortable: false,
          },
        ],
        data: [],
      },
      process: {
        plantCd: null,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        editFlag: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      attachInfoL1Photo: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UP',
        taskKey: '',
      },
      attachInfoL2Photo: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_PHOTO',
        taskKey: '',
      },
      attachInfoL1Hazard: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_HAZARD',
        taskKey: '',
      },
      selectedProcess: {
        plantCd: '',
        processCd: '',
        processName: '',
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      saveUrl: '',
      editable: true,
      isSave: false,
      saveType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    title() {
      let title = '공정 상세';
      if (this.process) {
        if (this.process.processLevelCd == '20') {
          title = '단위공정 상세';
        } else {
          title = '상위공정 상세';
        }
      }
      return title;
    },
    processEditable() {
      return Boolean(this.selectedProcess.processCd) || this.process.editFlag === 'C'
    },
    isUpProcess() {
      return this.process.processLevelCd !== '20'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.process.list.url;
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.checkUrl = selectConfig.mdm.process.check.url;
      this.saveUrl = transactionConfig.mdm.process.insert.url;
      // code setting
      // list setting
      this.getList();
    },
    getSearchList() {
      this.getList();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      // 상세조회
      this.selectedProcess = row;
      // 상세조회
      this.$http.url = this.$format(this.detailUrl, this.selectedProcess.processCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.process, _result.data);

        this.$set(this.attachInfoL1Photo, 'taskKey', row.processCd)
        this.$set(this.attachInfoL1Hazard, 'taskKey', row.processCd);
        this.$set(this.attachInfoL2Photo, 'taskKey', row.processCd);
      },);
    },
    reset() {
      Object.assign(this.$data.selectedProcess, this.$options.data().selectedProcess);
      Object.assign(this.$data.process, this.$options.data().process);

      this.$set(this.attachInfoL1Photo, 'taskKey', '');
      this.$set(this.attachInfoL1Hazard, 'taskKey', '');
      this.$set(this.attachInfoL2Photo, 'taskKey', '');
    },
    addParent() {
      this.process = {
        plantCd: this.searchParam.plantCd,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '10',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        editFlag: 'C',
      };
      this.$set(this.attachInfoL1Photo, 'taskKey', '')
      this.$set(this.attachInfoL1Hazard, 'taskKey', '');
    },
    addChild() {
      this.process = {
        plantCd: this.selectedProcess.plantCd,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: this.selectedProcess.processCd,
        upProcessName: this.selectedProcess.processName,
        processLevelCd: '20',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'N',
        workCycleCd: null,
        workingTime: null,
        temperature: '',
        editFlag: 'C',
      };
      this.$set(this.attachInfoL2Photo, 'taskKey', '');
    },
    processChange(processCd, process) {
      this.$set(this.process, 'plantCd', process.plantCd)
    },
    saveData() {
      if (this.process.processCd) {
        this.saveType = 'PUT'
        this.saveUrl = transactionConfig.mdm.process.update.url;
      } else {
        this.saveType = 'POST'
        this.saveUrl = transactionConfig.mdm.process.insert.url;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          // 중복체크
          this.$http.url = this.$format(this.checkUrl, this.process.processNo);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (_result.data > 0 && !this.process.processCd) {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message: '동일한 공정관리번호가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            } else {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.process.regUserId = this.$store.getters.user.userId
                  this.process.chgUserId = this.$store.getters.user.userId

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      if (this.saveType == 'POST') {
        this.$set(this.attachInfoL1Photo, 'taskKey', _result.data)
        this.$set(this.attachInfoL1Photo, 'isSubmit', uid())
        this.$set(this.attachInfoL1Hazard, 'taskKey', _result.data);
        this.$set(this.attachInfoL1Hazard, 'isSubmit', uid())
        this.$set(this.attachInfoL2Photo, 'taskKey', _result.data)
        this.$set(this.attachInfoL2Photo, 'isSubmit', uid())
      }
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({
        processCd: _result.data,
        processName: this.process.processName,
        plantCd: this.process.plantCd,
      })
    },
  }
};
</script>
